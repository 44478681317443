<template>
  <span class="text-container px-1">
    <template v-if="!single_line">
      <slot name="order"></slot>
      <span v-if="!showText">
        <span>
          {{ Text.substring(0, Large).trim() }}
        </span>
        <b-icon
          v-if="Text.length > Large"
          class="icon-class"
          icon="three-dots"
          v-b-tooltip.right.hover.v-secondary.noninteractive="tooltip_text"
        ></b-icon>
        <b-icon
          v-if="Text.length > Large"
          @click="showText = !showText"
          icon="chevron-right"
          class="cursor-pointer"
          v-b-tooltip.top.hover.v-secondary.noninteractive="
            'Click para ver más'
          "
        ></b-icon>
      </span>
      <span v-else>
        {{ Text }}
        <b-icon
          v-if="Text.length > Large"
          @click="showText = !showText"
          icon="chevron-left"
          class="cursor-pointer"
          v-b-tooltip.top.hover.v-secondary.noninteractive="
            'Click para ver menos'
          "
        ></b-icon
      ></span>
    </template>
    <template v-else>
      <div
        class="single-line"
        @click="(e) => e.target.classList.toggle('single-line')"
        v-b-tooltip.right.hover.v-secondary.noninteractive="tooltip_text"
      >
        <slot name="order"></slot>
        {{ Text }}
      </div>
    </template>
  </span>
</template>
<script>
export default {
  name: "BoundedTextContainer",
  props: {
    Text: {
      type: String,
      required: true,
    },
    Large: {
      type: Number,
      default: 100,
    },
    tooltip_text: {
      type: String,
      required: true,
    },
    single_line: {
      type: Boolean,
      default: false,
    },
    full_print: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showText: false,
    };
  },
  computed: {},
};
</script>
<style>
.single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-container {
  width: max-content !important;
}
.icon-class {
  vertical-align: bottom !important;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}
@media print {
  .single-line {
    overflow: hidden !important; /* Permitir que el contenido sea visible */
    white-space: normal !important; /* Permitir que el contenido envuelva */
    text-overflow: initial !important;
  }
}
</style>